import React from "react";
import CalculatorForm from "../../Components/Calculator/CalculatorForm.tsx";

export default function CalculatorPage() {
  return (
    <div>
      <div className="relative isolate overflow-hidden bg-blue-700 py-4 sm:py-16">

        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div
            className="mx-auto max-w-full lg:mx-0 flex justify-center"
            style={{ flexDirection: "column" }}
          >
            <h2 className="text-2xl font-medium tracking-tight text-white sm:text-6xl">
              Food Safety Risk Calculator
            </h2>
            <p className="mt-6 text-sm leading-5 text-gray-300">
              Complete all the steps to calculate the risk
            </p>
          </div>
        </div>
      </div>

     <CalculatorForm />
    </div>
  );
}
